import request from '@/utils/request'
// 获取小区(组)列表[适用于下拉列表选择]
export function WeGetRegionList(query) {
  return request({
    url: 'Region/WeGetRegionList',
    method: 'get',
    params: query,
  })
}
// 获取楼宇信息列表[小程序，适用于下拉列表]
export function WeGetBuildList(query) {
  return request({
    url: 'Build/WeGetBuildList',
    method: 'get',
    params: query,
  })
}
// 获取楼宇单元列表[小程序，适用于下拉列表]
export function WeGetBuildUnitList(query) {
  return request({
    url: 'Build/WeGetBuildUnitList',
    method: 'get',
    params: query,
  })
}
// 获取楼层列表[小程序，适用于下拉列表]
export function WeGetBuildFloorList(query) {
  return request({
    url: 'Build/WeGetBuildFloorList',
    method: 'get',
    params: query,
  })
}
// 获取房屋列表[小程序，适用于下拉列表]
export function WeGetBuildRoomList(query) {
  return request({
    url: 'Build/WeGetBuildRoomList',
    method: 'get',
    params: query,
  })
}
// 获取指定类别的代码列表
export function GetCodeEntrysList(query) {
  return request({
    url: 'Code/GetCodeEntrysList',
    method: 'get',
    params: query,
  })
}

export function SaveCheck(data) {
  return request({
    url: 'Vaccine/SaveCheck',
    method: 'post',
    data,
  })
}
// 居民端-保存疫苗接种排查信息
export function WxSaveCheck(data) {
  return request({
    url: 'Vaccine/WxSaveCheck',
    method: 'post',
    data,
  })
}
export function SaveReporting(data) {
  return request({
    url: 'Vaccine/SaveReporting',
    method: 'post',
    data,
  })
}
export function SaveOutReporting(data) {
  return request({
    url: 'Vaccine/SaveOutReporting',
    method: 'post',
    data,
  })
}
export function SaveDesireHelp(data) {
  return request({
    url: 'Desire/SaveDesireHelp',
    method: 'post',
    data,
  })
}
export function WeBindRegion(data) {
  return request({
    url: 'Member/WeBindRegion',
    method: 'post',
    data,
  })
}
export function WxGetDesireHelpList(query) {
  return request({
    url: 'Desire/WxGetDesireHelpList',
    method: 'get',
    params: query,
  })
}
export function WxGetDesireHelpPage(query) {
  return request({
    url: 'Desire/WxGetDesireHelpPage',
    method: 'get',
    params: query,
  })
}
export function WxHandlerDesireHelp(data) {
  return request({
    url: 'Desire/WxHandlerDesireHelp',
    method: 'post',
    data,
  })
}
export function WxGetHandlerDesireHelp(query) {
  return request({
    url: 'Desire/WxGetHandlerDesireHelp',
    method: 'get',
    params: query,
  })
}
export function WxApplyResident(data) {
  return request({
    url: 'GHCapp/WxApplyResident',
    method: 'post',
    data,
  })
}
// 会员获取绑定信息详情
export function WeGetMemberInfo(params) {
  return request({
    url: 'GHCapp/WeGetMemberInfo',
    method: 'get',
    params,
  })
}
// 微信获取便民服务清单
export function WeGetServiceList(params) {
  return request({
    url: 'GHCapp/WeGetTicketList',
    method: 'get',
    params,
  })
}
export function WeEnrolActivity(data) {
  return request({
    url: 'Activity/WeEnrolActivity',
    method: 'post',
    data,
  })
}
export function WxSignActive(data) {
  return request({
    url: 'GHCapp/WxSignActive',
    method: 'post',
    data,
  })
}
export function WxScoreDetailList(params) {
  return request({
    url: 'GHCapp/WxScoreDetailList',
    method: 'get',
    params,
  })
}
export function WeGetAppletOrganList(params) {
  return request({
    url: 'Organ/WeGetAppletOrganList',
    method: 'get',
    params,
  })
}
// 用户申请保存住户信息
export function WxApplyLCResident(data) {
  return request({
    url: 'GHCapp/WxApplyLCResident',
    method: 'post',
    data,
  })
}

export function WeGetGridList(params) {
  return request({
    url: 'Grid/WeGetGridList',
    method: 'get',
    params,
  })
}

export function WeGetLCMemberInfo(params) {
  return request({
    url: 'GHCapp/WeGetLCMemberInfo',
    method: 'get',
    params,
  })
}
// 居民端判断是否注册会员
export function WxIsMember(data) {
  return request({
    url: 'Member/WxIsMember',
    method: 'post',
    data,
  })
}
// 获取会员详情
export function WeMyMemberInfo(params) {
  return request({
    url: 'Member/WeMyMemberInfo',
    method: 'get',
    params,
  })
}
// 获取指定级别、指定类型的组织机构信息（无用户验证）
export function GetOrgansNullToken(query) {
  return request({
    url: 'Organ/GetOrgansNullToken',
    method: 'get',
    params: query,
  })
}

// 居民端-保存隔离人员信息
export function WxSaveIsolation(data) {
  return request({
    url: 'Isolation/WxSaveIsolation',
    method: 'post',
    data,
  })
}

// 居民端-保存商铺信息
export function WxSaveShop(data) {
  return request({
    url: 'Shop/WxSaveShop',
    method: 'post',
    data,
  })
}

// 居民端-获取我的商铺详情
export function WxSaveMyBank(data) {
  return request({
    url: 'Shop/WxSaveMyBank',
    method: 'post',
    data,
  })
}

// 居民端-重新绑定微信
export function WxReBindShop(data) {
  return request({
    url: 'Shop/WxReBindShop',
    method: 'post',
    data,
  })
}

// 居民端-保存企业信息
export function WxSaveEnterprise(data) {
  return request({
    url: 'Enterprise/WxSaveEnterprise',
    method: 'post',
    data,
  })
}

// 居民端-获取我的企业详情
export function WxMyGetEnterprise(query) {
  return request({
    url: 'Enterprise/WxMyGetEnterprise',
    method: 'get',
    params: query,
  })
}

// 居民端-重新绑定微信
export function WxReBindEnterprise(data) {
  return request({
    url: 'Enterprise/WxReBindEnterprise',
    method: 'post',
    data,
  })
}

// 居民端-保存场合防控措施
export function WxSaveMeasure(data) {
  return request({
    url: 'Measure/WxSaveMeasure',
    method: 'post',
    data,
  })
}

// 居民端-保存场合防控措施
export function WxSaveVisitMan(data) {
  return request({
    url: 'VisitMan/WxSaveVisitMan',
    method: 'post',
    data,
  })
}

// 根据姓名或身份证号查找居民信息
export function WxSearchResident(query) {
  return request({
    url: 'Resident/WxSearchResident',
    method: 'get',
    params: query,
  })
}
export function WxSaveReporting(data) {
  return request({
    url: 'Vaccine/WxSaveReporting',
    method: 'post',
    data,
  })
}
// 居民端-保存离洛信息
export function WxSaveOutReporting(data) {
  return request({
    url: 'Vaccine/WxSaveOutReporting',
    method: 'post',
    data,
  })
}

export function WxSaveNACheck(data) {
  return request({
    url: 'Vaccine/WxSaveNACheck',
    method: 'post',
    data,
  })
}

// 获取返洛人员核酸检测报备详情
export function WxGetNACheck(query) {
  return request({
    url: 'Vaccine/WxGetNACheck',
    method: 'get',
    params: query,
  })
}

export function WxSaveProService(data) {
  return request({
    url: 'Property/WxSaveProService',
    method: 'post',
    data,
  })
}
// 居民端-保存核酸检测登记信息
export function WxSaveNATestReg(data) {
  return request({
    url: 'NATestReg/WxSaveNATestReg',
    method: 'post',
    data,
  })
}
// 居民端保存核酸检测报备信息信息
export function WxSaveNATest(data) {
  return request({
    url: 'NATest/WxSaveNATest',
    method: 'post',
    data,
  })
}
// 居民端-保存聚集性活动报备信息
export function WxSaveActReport(data) {
  return request({
    url: 'ActReport/WxSaveActReport',
    method: 'post',
    data,
  })
}

// 会员注册
export function RegisterMember(data) {
  return request({
    url: 'Member/RegisterMember',
    method: 'post',
    data,
  })
}
// 修改个人信息
export function WxSaveMember(data) {
  return request({
    url: 'Member/WxSaveMember',
    method: 'post',
    data,
  })
}

// 商铺端-获取我的商铺详情
export function WxGetMyShop(query) {
  return request({
    url: 'Shop/WxGetMyShop',
    method: 'get',
    params: query,
  })
}
